import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d299157 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _2dac1355 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _619430fb = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _2f38fc6e = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _445cc9d2 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _e725992a = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _a1c9ea04 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _2d80d962 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _0cc829c1 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _2b27e857 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _4f063904 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _62a43aa0 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _be1c4070 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _22040798 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _485021b8 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _66e92464 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _ae308e66 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _ad93fe2c = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _447e17a8 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _edbde40e = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _4d7c0ceb = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _4d299157,
    name: "kits"
  }, {
    path: "/lojas",
    component: _2dac1355,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _619430fb,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _2f38fc6e,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _445cc9d2,
    name: "motos"
  }, {
    path: "/reset",
    component: _e725992a,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _a1c9ea04,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _2d80d962,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _0cc829c1,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _2b27e857,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _4f063904,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _62a43aa0,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _be1c4070,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _22040798,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _485021b8,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _66e92464,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _ae308e66,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _ad93fe2c,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _447e17a8,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _edbde40e,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _4d7c0ceb,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
